import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BiEdit, BiTrash } from "react-icons/bi";

const CoursesTable = () => {
  const [courses, setCourses] = useState([]);
  const token = localStorage.getItem("authToken");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      if (token) {
        try {
          const response = await axios.get(
            "https://smc.dltse.com/api/v1/admin/courses",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCourses(response.data.data || []);
        } catch (error) {
          console.error("Error fetching courses:", error);
          setCourses([]);
        }
      }
    };

    fetchCourses();
  }, [token]);

  const handleDelete = async (courseId) => {
    try {
      await axios.delete(
        `https://smc.dltse.com/api/v1/admin/courses/${courseId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCourses(courses.filter((course) => course.id !== courseId));
      alert("Course deleted successfully");
    } catch (error) {
      console.error("Error deleting course:", error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCourses = courses.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(courses.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const goToNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const goToPreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));

  return (
    <>
      {!token ? (
        <p>Please log in to view the courses.</p>
      ) : (
        <div className="overflow-x-auto">
          <button
            className="bg-blue-500 px-3 py-2 text-white"
            onClick={() => navigate("/admin/course/create")}
          >
            Add Courses
          </button>
          <table className="min-w-full border border-gray-300 mt-4 bg-white">
            <thead className="bg-gray-200 text-gray-600">
              <tr>
                <th className="border px-4 py-2">#</th>
                <th className="border px-4 py-2">Course Name</th>
                <th className="border px-4 py-2">Description</th>
                <th className="border px-4 py-2">Duration</th>
                <th className="border px-4 py-2">Level</th>
                <th className="border px-4 py-2">Eligibility</th>
                <th className="border px-4 py-2">Streams</th>
                <th className="border px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentCourses.length > 0 ? (
                currentCourses.map((course, index) => (
                  <tr key={course.id}>
                    <td className="border px-4 py-2">
                      {indexOfFirstItem + index + 1}
                    </td>
                    <td className="border px-4 py-2">{course.name}</td>
                    <td className="border px-4 py-2">
                      {course.description.length > 30
                        ? `${course.description.substring(0, 30)}...`
                        : course.description}
                    </td>
                    <td className="border px-4 py-2">
                      {course.duration} years
                    </td>
                    <td className="border px-4 py-2">{course.level}</td>
                    <td className="border px-4 py-2">
                      {course.eligibility.length > 30
                        ? `${course.eligibility.substring(0, 30)}...`
                        : course.eligibility}
                    </td>
                    <td className="border px-4 py-2">
                      {course.streams && course.streams.length > 0 ? (
                        <table className="min-w-full border">
                          <thead className="bg-gray-200 text-gray-600">
                            <tr>
                              <th className="border px-2 py-1">Stream Name</th>
                              <th className="border px-2 py-1">Subjects</th>
                              <th className="border px-2 py-1">Fees</th>
                            </tr>
                          </thead>
                          <tbody>
                            {course.streams.map((stream) => (
                              <tr key={stream.id}>
                                <td className="border px-2 py-1">
                                  {stream.name}
                                </td>
                                <td className="border px-2 py-1">
                                  {stream.subjects.join(", ")}
                                </td>
                                <td className="border px-2 py-1">
                                  {stream.fees}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        "No streams available"
                      )}
                    </td>
                    <td className="border px-4 py-2">
                      <button
                        onClick={() => alert(`Edit course: ${course.id}`)}
                        className="flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2"
                      >
                        <BiEdit className="mr-1" />
                      </button>
                      <button
                        onClick={() => handleDelete(course.id)}
                        className="flex items-center bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                      >
                        <BiTrash className="mr-1" /> 
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="border px-4 py-2 text-center">
                    No courses available
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="flex justify-center mt-4 space-x-2">
            <button
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
              className="px-3 py-1 rounded bg-gray-300 hover:bg-gray-400 disabled:opacity-50"
            >
              Previous
            </button>
            {[...Array(totalPages).keys()].map((page) => (
              <button
                key={page + 1}
                onClick={() => paginate(page + 1)}
                className={`px-3 py-1 rounded ${
                  currentPage === page + 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200"
                }`}
              >
                {page + 1}
              </button>
            ))}
            <button
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
              className="px-3 py-1 rounded bg-gray-300 hover:bg-gray-400 disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CoursesTable;
