import React from 'react';
import {Link } from 'react-router-dom';

const AdminLoginPage = () => {

  return (
    <div
      className="h-screen w-full flex items-center justify-center bg-cover bg-center"
      style={{
        backgroundImage: `url('/assets/bg1.jpg')`,
      }}
    >
      {/* Left Side - Information Section */}
      <div className="hidden md:flex flex-col justify-center items-start w-1/2 h-full p-10 bg-blue-900 bg-opacity-70 text-white">
        <h2 className="text-2xl font-bold mb-4">Why Sign Up?</h2>
        <ul className="list-disc space-y-2 text-lg ml-6">
        <li>View College Brochures</li>
            <li>Check Detailed Fees</li>
            <li>Shortlist and Apply to Colleges</li>
            <li>Ask Questions to Senior Counselors</li>
          <li>Never Miss Important Deadlines</li>
        </ul>
      </div>

      {/* Right Side - Login Form */}
      <div className="w-full md:w-1/2 max-w-md bg-white bg-opacity-90 rounded-lg shadow-lg p-8 ml-auto mr-12">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Login to Your Account</h2>
        <form>
            <div className="mb-4">
              <label className="block text-gray-600 mb-2">Email</label>
              <input
                type="email"
                placeholder="Enter your email"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-600 mb-2">Password</label>
              <input
                type="password"
                placeholder="Enter your password"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Login
            </button>
          </form>
          <div className="mt-6 flex justify-between text-sm text-blue-600">
            <Link to="/" className="hover:underline">Register as a New User</Link>
            <Link to="/admin/login" className="hover:underline">Login as Admin</Link>
          </div>
      </div>
    </div>
  );
};

export default AdminLoginPage;
