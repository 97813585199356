import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const CreateCourse = () => {
    const navigate=useNavigate();
  const [course, setCourse] = useState({
    name: '',
    description: '',
    duration: 1,
    level: '',
    eligibility: '',
    streams: [
      { name: '', subjects: [''], fees: '' },
    ],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourse({ ...course, [name]: value });
  };

  const handleStreamChange = (index, e) => {
    const { name, value } = e.target;
    const updatedStreams = course.streams.map((stream, i) =>
      i === index ? { ...stream, [name]: value } : stream
    );
    setCourse({ ...course, streams: updatedStreams });
  };

  const handleSubjectChange = (streamIndex, subjectIndex, value) => {
    const updatedStreams = course.streams.map((stream, i) =>
      i === streamIndex
        ? {
            ...stream,
            subjects: stream.subjects.map((subject, j) =>
              j === subjectIndex ? value : subject
            ),
          }
        : stream
    );
    setCourse({ ...course, streams: updatedStreams });
  };

  const addStream = () => {
    setCourse({
      ...course,
      streams: [...course.streams, { name: '', subjects: [''], fees: '' }],
    });
  };

  const addSubject = (index) => {
    const updatedStreams = course.streams.map((stream, i) =>
      i === index ? { ...stream, subjects: [...stream.subjects, ''] } : stream
    );
    setCourse({ ...course, streams: updatedStreams });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('authToken'); 
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/create-course`,
        course,
        {
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        }
      );
      if (response.status === 200) {
        alert('Course created successfully!');
        setCourse({
            name: '',
            description: '',
            duration: '',
            level: '',
            eligibility: '',
            streams: [
              { name: '', subjects: [], fees: '' },
            ],
          });
          navigate("/admin/courses")
      }
    } catch (error) {
      console.error('Error creating course:', error);
      alert('Failed to create course. Please check the console for details.');
    }
  };
  

  return (
    <div className="container mx-auto p-8 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6">Create New Course</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Course Name</label>
          <input
            type="text"
            name="name"
            value={course.name}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Course Name"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Description</label>
          <textarea
            name="description"
            value={course.description}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Course Description"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Duration (Years)</label>
          <input
            type="number"
            name="duration"
            value={course.duration}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Level</label>
          <input
            type="text"
            name="level"
            value={course.level}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="e.g., Bachelor, Master"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Eligibility</label>
          <input
            type="text"
            name="eligibility"
            value={course.eligibility}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Eligibility Requirements"
            required
          />
        </div>

        {/* Stream Sections */}
        {course.streams.map((stream, index) => (
          <div key={index} className="mb-6 border-b pb-4">
            <h3 className="text-lg font-semibold">Stream {index + 1}</h3>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Stream Name</label>
              <input
                type="text"
                name="name"
                value={stream.name}
                onChange={(e) => handleStreamChange(index, e)}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Stream Name"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Fees</label>
              <input
                type="number"
                name="fees"
                value={stream.fees}
                onChange={(e) => handleStreamChange(index, e)}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Stream Fees"
                required
              />
            </div>

            {/* Subjects */}
            {stream.subjects.map((subject, subIndex) => (
              <div key={subIndex} className="flex items-center mb-2">
                <input
                  type="text"
                  value={subject}
                  onChange={(e) =>
                    handleSubjectChange(index, subIndex, e.target.value)
                  }
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 mr-2"
                  placeholder="Subject"
                  required
                />
                <button
                  type="button"
                  className="text-blue-500"
                  onClick={() => addSubject(index)}
                >
                  + Add Subject
                </button>
              </div>
            ))}
          </div>
        ))}

        <button
          type="button"
          onClick={addStream}
          className="px-3 bg-blue-500 text-white py-2 rounded-lg mt-4 hover:bg-blue-600 transition duration-200"
        >
          + Add Stream
        </button>

        <button
          type="submit"
          className="px-3 ml-4 bg-green-500 text-white py-2 rounded-lg mt-6 hover:bg-green-600 transition duration-200"
        >
          Submit Course
        </button>
      </form>
    </div>
  );
};

export default CreateCourse;
