import React from "react";
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import CreateCourse from "./CreateCourse";

export default function CreateCourseMain() {
  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 flex flex-col">
        <Navbar />
        <div className="p-6 bg-gray-100 flex-1 overflow-y-auto">
          <CreateCourse />
        </div>
      </div>
    </div>
  );
}
