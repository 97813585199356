import React from 'react'
import Navbar from '../../../user/components/navbar/Navbar'
import Footer from '../../../user/components/footer/Footer'
import ForgotPasswordPage from './ForgotPassword'
import Subscribe from '../../../user/components/home/Subscribe'

export default function ForgetPasswordMain() {
  return (
    <div>
        <Navbar/>
        <ForgotPasswordPage/>
        <Subscribe/>
        <Footer/>
    </div>
  )
}
