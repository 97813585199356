import React from 'react'
import Navbar from '../navbar/Navbar'
import Login from './Login'
import Footer from '../footer/Footer'
import Subscribe from '../home/Subscribe'

export default function LoginMain() {
  return (
    <>
        <Navbar/>
        <Login/>
        <Subscribe/>
        <Footer/>
    </>
  )
}
