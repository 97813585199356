import React, { useState } from 'react';
import Sidebar from './sidebar/Sidebar';
import Navbar from './navbar/Navbar';

const AdminHome = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to control sidebar visibility

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />

      {/* Main content area */}
      <div className="flex-1 flex flex-col">
        <Navbar toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} /> {/* Pass toggle function to Navbar */}

        <div className="p-6 bg-gray-100 flex-1">
          <h1 className="text-2xl font-bold">Welcome to the Admin Panel</h1>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
