import React from 'react'
import Navbar from '../../../user/components/navbar/Navbar'
import Footer from '../../../user/components/footer/Footer'
import AdminLoginPage from './AdminLogin'
import Subscribe from '../../../user/components/home/Subscribe'

export default function AdminLoginMain() {
  return (
    <div>
        <Navbar/>
        <AdminLoginPage/>
        <Subscribe/>
        <Footer/>
    </div>
  )
}
