import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate,Link } from 'react-router-dom';
import { useAuth } from '../../../App';

const AdminLoginPage = () => {
  const navigate = useNavigate();
  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const { login } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
  
    try {
      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/admin/login`;
      console.log(apiUrl); 
  
      const response = await axios.post(apiUrl, {
        login_id: loginId,
        password: password,
      });
  
      if (response.data.success) {
        setMessage(response.data.message);
        localStorage.setItem('authToken', response.data.data.token);
        login();
        navigate('/admin/dashboard');
      } else {
        setMessage('Login failed. Please check your credentials.');
      }
    } catch (error) {
      console.error("Login error:", error);
      setMessage('Error logging in. Please try again.');
    }
  };
  
  return (
    <div
      className="h-screen w-full flex items-center justify-center bg-cover bg-center"
      style={{
        backgroundImage: `url('/assets/bg2.jpg')`,
      }}
    >
      <div className="hidden md:flex flex-col justify-center items-start w-1/2 h-full p-10 bg-blue-900 bg-opacity-70 text-white">
        <h2 className="text-3xl font-bold mb-4">Login if you are:</h2>
        <ul className="list-disc space-y-2 text-lg ml-6">
          <li>A Marketing Manager of this Institute</li>
          <li>A person in-charge of this Institute</li>
          <li>A person who can be contacted for any further enquiry</li>
        </ul>
      </div>

      <div className="w-full md:w-1/2 max-w-md bg-white bg-opacity-90 rounded-lg shadow-lg p-8 ml-auto mr-12">
        <h2 className="text-2xl font-bold mb-6 text-gray-700">Admin Login</h2>
        {message && <p className="text-center text-red-500 mb-4">{message}</p>}
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-gray-600 mb-2">Login ID</label>
            <input
              type="text"
              value={loginId}
              onChange={(e) => setLoginId(e.target.value)}
              placeholder="Enter your login Id"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-600 mb-2">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-200"
          >
            Login
          </button>
          <p className="text-center text-gray-500 text-sm mt-4">
  <Link to="/admin/forgot-password" className="text-blue-500 hover:underline">
    Forgot Password?
  </Link>
</p>
        </form>
      </div>
    </div>
  );
};

export default AdminLoginPage;
