import React, { useState, useEffect, createContext, useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./user/components/home/Home";
import AdminHome from "./admin/components/Home";
import LoginMain from "./user/components/login/LoginMain";
import AdminLogin from "./admin/components/login/AdminLoginMain";
import CreateCourseMain from "./admin/components/course/CourseCreateMain";
import CourseTableMain from "./admin/components/course/CourseTableMain";
import ForgetPasswordMain from "./admin/components/login/ForgetPasswordMain";

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    () => JSON.parse(localStorage.getItem("isAuthenticated")) || false
  );

  useEffect(() => {
    localStorage.setItem("isAuthenticated", JSON.stringify(isAuthenticated));
  }, [isAuthenticated]);

  const login = () => setIsAuthenticated(true);
  const logout = () => setIsAuthenticated(false);

  return (
    <div className="App">
      <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LoginMain />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route
              path="/admin/dashboard"
              element={
                <ProtectedRoute>
                  <AdminHome />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/course/create"
              element={
                <ProtectedRoute>
                  <CreateCourseMain />
                </ProtectedRoute>
              }
            />
            <Route path="/admin/courses"element={<ProtectedRoute><CourseTableMain /></ProtectedRoute>}/>
            <Route path="/admin/forgot-password"element={<ForgetPasswordMain/>}/>
          </Routes>
        </BrowserRouter>
      </AuthContext.Provider>
    </div>
  );
}

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? children : <Navigate to="/admin/login" replace />;
};
